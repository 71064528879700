import React from "react";
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () =>{
    return(
        <div className="policyContainer">
            <div className="secDiv">
                <p className="sectionTitle">PRIVACY POLICY</p>
                <p className="sectionContent">
                We at “GameBuzz Media” have created this Privacy Policy to demonstrate our commitment to your privacy. The following reveals our information gathering and dissemination practices. By accessing and/or using 
                “GameBuzz Media” website you are accepting the practices described in this privacy policy.
                </p>
            </div>

            <div className="secDiv">
            <h2 className="sectionsubTitle">AGREEMENT</h2>
            <p className="sectionContent">By using / installing the “GameBuzz Media” games or services on your devices is stated that, you agree the terms and conditions detailed in these privacy policies, including what, 
            when, why collecting user’s personal and non – personal information.
            </p>

            <div className="dataCollectionInfoSection">
            <p className="sectionContent">Here we collect the information from the user in three 
                different methods:
            </p>

            <p className="sectionContent"><span>Non – personal information:</span> Third parties will automatically identify the time when you launch or open or using a “GameBuzz Media” games, at that time they may collect the user information without limitations, 
            we integrate SDK of third parties in our apps / games to fetch the data which helps to 
            provide appropriate services to the users. Cookies and local storage may also be stored on your device via information gathering technologies used within the “GameBuzz Media”.
            </p>

            <p className="sectionContent">
            <span>Personal information:</span> When you are using a “GameBuzz Media” games, third parties will automatically collect the user details with the help of SDK integrated in our apps and games.
            </p>

            <p className="sectionContent">
            <span>User provided information:</span> We collect the information voluntarily given by the user, such as feedback, signup (when user wants to create account), Facebook login, and other information asked to fill before or after the use of services provided by “GameBuzz Media”.
            </p>
            </div>

            <div className="otherdetailsInfoSection">
                <p className="sectionContent">
                We may share users personal information to third parties if there is a high priority, please find the following cases where we encounter with high priority of data sharing:
                </p>

                <ul className="sectionContentList">
                    <li className="sectionListItem">
                    To identify, eliminate, or otherwise address fraud, security or technical issues;
                    </li>
                    <li className="sectionListItem">
                        To respond to claims content violation of any right of a third party;
                    </li>
                    <li className="sectionListItem">
                    To obey any applicable law, regulation, legal process, subpoena or governmental request;
                    </li>
                    <li className="sectionListItem">
                    To enforce this Privacy Policy and/or the terms of use and other “GameBuzz Media” policies, including investigation of potential violations thereof;
                    </li>
                    <li className="sectionListItem">
                        To protect the personal safety, property, or rights of the Company, its users or the general public;
                    </li>
                    <li className="sectionListItem">
                    Change took place in the ownership, merger with others, acquisition or purchase of all or substantially all of the assets of Company;
                    </li>
                    <li className="sectionListItem">
                    To let our third parties, affiliates and partners to deliver with advertisements, support or services;
                    </li>
                    <li className="sectionListItem">
                    As part of a competition, receiving rewards or otherwise interact with our social media channels;
                    </li>
                </ul>
            </div>

            <div className="noteSection">
                <p className="sectionContent">
                <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                </p>
            </div>

            </div>

            <div className="secDiv">
            <h2 className="sectionsubTitle">CHANGES TO THE PRIVACY POLICY</h2>
            <p className="sectionContent">This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and we are not responsible for informing you about changes, you should keep notice by yourself. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
            <p className="sectionContent">
            If there are any questions regarding this privacy policy you may contact us here:  www.gamebuzzmedia.com and we will make an effort to reply within a reasonable timeframe.
            </p>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;